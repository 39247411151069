<template>
    <div class="aside-desktop-contacts">
        <div v-if="enableContactSidebar && telephoneNumber" class="aside-desktop-contacts__item">
            <button
                v-if="telephoneNumber"
                type="button"
                class="menu-contact-button"
                @click="isPhoneNumberVisible = !isPhoneNumberVisible"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.015" viewBox="0 0 30 26.015">
                    <g id="Groupe_57265" data-name="Groupe 57265" transform="translate(0 0)">
                        <path
                            id="Tracé_59372"
                            data-name="Tracé 59372"
                            d="M0,12.141c.095-.606.168-1.216.288-1.816A12.538,12.538,0,0,1,2.3,5.628c.229-.333.457-.415.7-.254s.253.422.032.742a11.731,11.731,0,0,0-1.995,4.949,11.859,11.859,0,0,0,2.384,9.367,11.514,11.514,0,0,0,7.085,4.442,11.8,11.8,0,0,0,11.533-3.793,11.462,11.462,0,0,0,3.007-6.722,22.516,22.516,0,0,0,.013-2.369c-.365.071-.723.155-1.085.209a8.64,8.64,0,0,1-3.085-.1.548.548,0,0,0-.393.1c-.554.416-1.094.85-1.636,1.282a.616.616,0,0,1-.7.14.584.584,0,0,1-.312-.64c.062-.621.127-1.243.213-1.861a.356.356,0,0,0-.169-.385,5.7,5.7,0,0,1-2.315-3.541,5.421,5.421,0,0,1,1.788-5.152c.063-.061.128-.12.191-.18a.538.538,0,0,0,.035-.089c-.552-.174-1.092-.388-1.652-.512A11.983,11.983,0,0,0,4.516,4.365c-.05.047-.1.1-.149.141a.433.433,0,0,1-.629,0,.424.424,0,0,1,.028-.626A15.7,15.7,0,0,1,5.2,2.626a12.838,12.838,0,0,1,13-1.529.536.536,0,0,0,.51-.025,8.242,8.242,0,0,1,8.17.053c.069.037.135.078.2.121.275.181.353.41.218.639a.455.455,0,0,1-.687.1A7.084,7.084,0,0,0,22.8.91a6.914,6.914,0,0,0-4.7,1.65,4.605,4.605,0,0,0,.169,7.351.121.121,0,0,0,.024.016,1.16,1.16,0,0,1,.623,1.547,8.461,8.461,0,0,0-.12.915,8.843,8.843,0,0,0,.864-.673,1.75,1.75,0,0,1,1.734-.445,6.771,6.771,0,0,0,6.55-2.091A4.427,4.427,0,0,0,28.157,3.4c-.272-.361-.293-.613-.068-.793s.5-.1.772.262a5.4,5.4,0,0,1-.99,7.606,10.027,10.027,0,0,1-1.622,1.033.448.448,0,0,0-.288.51,12.38,12.38,0,0,1-1.716,7.522,12.625,12.625,0,0,1-9.623,6.371A12.93,12.93,0,0,1,.4,16.2c-.17-.678-.234-1.382-.347-2.074C.037,14.028.018,13.934,0,13.84v-1.7"
                            transform="translate(0 0)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_59373"
                            data-name="Tracé 59373"
                            d="M95.4,58.743c-.054.449-.085.9-.167,1.347a1.769,1.769,0,0,1-1.559,1.366.75.75,0,0,0-.654.563,3.074,3.074,0,0,0,.064,1.894,4.86,4.86,0,0,0,1.378,2.315,4.525,4.525,0,0,0,.387.3.944.944,0,0,0,.774.168,2.543,2.543,0,0,1,2.356.627,15.887,15.887,0,0,1,1.663,1.911c.591.827.271,1.569-.817,2.01a6.889,6.889,0,0,1-4.891.047,8.208,8.208,0,0,1-4.1-3.023,10.534,10.534,0,0,1-2.295-6.8,4.9,4.9,0,0,1,1.769-3.707,5.834,5.834,0,0,1,3.864-1.383c1.308-.014,2.091.573,2.176,1.589.021.256,0,.514,0,.771l.048,0m-1.045.005h-.032a6.967,6.967,0,0,0,0-.77c-.062-.56-.387-.8-1.106-.8a4.685,4.685,0,0,0-3.115,1.1,4.191,4.191,0,0,0-1.526,3.139,9.924,9.924,0,0,0,2.342,6.694,7.139,7.139,0,0,0,3.739,2.544,5.572,5.572,0,0,0,3.59-.073c.694-.262.835-.553.439-1.069a21.29,21.29,0,0,0-1.443-1.645,1.506,1.506,0,0,0-1.522-.379,1.561,1.561,0,0,1-1.169-.158,5.267,5.267,0,0,1-.958-.675,5.654,5.654,0,0,1-1.6-2.925,3.209,3.209,0,0,1,.134-2.151,1.393,1.393,0,0,1,1.176-.863.986.986,0,0,0,.957-.883c.043-.361.067-.724.1-1.086"
                            transform="translate(-81.318 -51.215)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_59374"
                            data-name="Tracé 59374"
                            d="M254.071,56.266q1.661,0,3.322,0c.431,0,.66.185.656.52s-.229.507-.636.507q-3.339,0-6.679,0c-.454,0-.73-.284-.625-.639a.543.543,0,0,1,.606-.39c1.119.005,2.238,0,3.357,0"
                            transform="translate(-232.286 -51.886)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_59375"
                            data-name="Tracé 59375"
                            d="M254.124,86.957q-1.609,0-3.218,0a1.623,1.623,0,0,1-.34-.023.5.5,0,0,1,.018-.985,2.137,2.137,0,0,1,.341-.018q3.218,0,6.437,0a1.757,1.757,0,0,1,.374.025.454.454,0,0,1,.38.462.459.459,0,0,1-.366.508,1.527,1.527,0,0,1-.373.03q-1.626,0-3.252,0"
                            transform="translate(-232.356 -79.243)"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </button>
        </div>
        <template v-if="isPhoneNumberVisible && telephoneNumber">
            <a v-if="telephoneNumber" :href="`tel:+${sanitizeNumber(telephoneNumber)}`" class="menu-contact-tel">
                +{{ sanitizeNumber(telephoneNumber) }}
            </a>
        </template>
        <template v-else>
            <template v-if="enableContactForm">
                <svg
                    class="aside-desktop-contacts__item"
                    v-scroll-to="formAnchor"
                    :href="formAnchor"
                    data-name="Groupe 57259"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="30"
                    height="29.996"
                    viewBox="0 0 30 29.996"
                    :style="{ opacity: !isPhoneNumberVisible ? 1 : 0 }"
                    @click="onContactForm"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_2240"
                                data-name="Rectangle 2240"
                                width="30"
                                height="29.996"
                                fill="#fff"
                            />
                        </clipPath>
                    </defs>
                    <g id="Groupe_57258" data-name="Groupe 57258" clip-path="url(#clip-path)">
                        <path
                            id="Tracé_59370"
                            data-name="Tracé 59370"
                            d="M0,10.127a8.041,8.041,0,0,1,.461-1.25A3.394,3.394,0,0,1,3.526,7.048c1.973-.027,3.946-.008,5.92-.006a.614.614,0,0,1,.69.5.572.572,0,0,1-.369.63,1.527,1.527,0,0,1-.434.042c-1.9,0-3.79,0-5.685,0A2.369,2.369,0,0,0,1.274,9.938a2.708,2.708,0,0,0-.1.721q-.009,5.641,0,11.282A2.371,2.371,0,0,0,3.6,24.374c.283,0,.567.011.85,0a.736.736,0,0,1,.732.428c.586,1.036,1.188,2.064,1.784,3.094.053.091.109.181.185.306.07-.111.128-.2.179-.284.6-1.031,1.2-2.058,1.781-3.1a.8.8,0,0,1,.779-.45q6.652.011,13.3,0a2.37,2.37,0,0,0,2.457-2.055,2.407,2.407,0,0,0,.023-.38q0-5.48,0-10.96v-.22l-.052-.014c-.059.095-.12.188-.176.285q-2.115,3.663-4.232,7.325a1.325,1.325,0,0,1-.326.4c-1.208.9-2.425,1.779-3.639,2.666a.59.59,0,0,1-.685.062.568.568,0,0,1-.283-.6c.007-.075,0-.152,0-.263H5.406c-.454,0-.72-.223-.715-.588s.279-.582.731-.583c1.084,0,2.169,0,3.253,0H16.43l.25-2.345H5.5c-.539,0-.811-.2-.811-.581s.286-.591.811-.591q5.553,0,11.107,0a.465.465,0,0,0,.481-.272c.324-.6.678-1.186,1.019-1.778.047-.081.086-.167.154-.3H5.513c-.546,0-.815-.187-.822-.57s.284-.6.83-.6q6.535,0,13.07.008a.556.556,0,0,0,.564-.333c.67-1.19,1.36-2.369,2.043-3.553.052-.09.1-.183.182-.333H15.1a2.688,2.688,0,0,1-.351-.013.574.574,0,0,1-.051-1.13,1.821,1.821,0,0,1,.378-.027c2.217,0,4.435-.005,6.652,0a.493.493,0,0,0,.5-.284q1.558-2.734,3.143-5.453a2.46,2.46,0,1,1,4.267,2.443c-.861,1.509-1.721,3.018-2.613,4.508a1.566,1.566,0,0,0-.27,1.633,1.864,1.864,0,0,1,.079.78q.006,5.627,0,11.253a3.545,3.545,0,0,1-3.614,3.618q-6.432,0-12.865,0a.47.47,0,0,0-.477.274Q8.841,27.662,7.762,29.49A2.962,2.962,0,0,1,7.268,30H7.033a2.952,2.952,0,0,1-.495-.506c-.668-1.123-1.335-2.248-1.956-3.4a.915.915,0,0,0-.966-.56A3.415,3.415,0,0,1,.623,24,4.262,4.262,0,0,1,0,22.436V10.127M25.513,3.416l-7.36,12.748,2.232,1.287L27.744,4.7,25.513,3.416M26.1,2.4l2.237,1.29c.142-.258.285-.48.391-.718a1.281,1.281,0,0,0-2.2-1.276c-.153.21-.271.446-.425.7M17.58,19.732l1.947-1.421-1.689-.972-.258,2.393"
                            transform="translate(0 -0.001)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_59371"
                            data-name="Tracé 59371"
                            d="M148.877,91.13a.578.578,0,0,1-.6-.556.584.584,0,1,1,1.167-.027.572.572,0,0,1-.569.583"
                            transform="translate(-136.668 -82.925)"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </template>
            <template v-if="enableContactSidebar && whatsappNumber">
                <a
                    v-if="whatsappNumber"
                    :href="`https://wa.me/${sanitizeNumber(whatsappNumber)}`"
                    target="_blank"
                    class="aside-desktop-contacts__item"
                    :style="{ opacity: !isPhoneNumberVisible ? 1 : 0 }"
                >
                    <svg
                        id="Groupe_57263"
                        data-name="Groupe 57263"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_2242"
                                    data-name="Rectangle 2242"
                                    width="30"
                                    height="30"
                                    fill="none"
                                />
                            </clipPath>
                        </defs>
                        <g id="Groupe_57262" data-name="Groupe 57262" clip-path="url(#clip-path)">
                            <path
                                id="Tracé_59376"
                                data-name="Tracé 59376"
                                d="M23.375,32.047H6.739c-.022-.057-.073-.058-.12-.059A4.029,4.029,0,0,1,5.5,31.771a7.559,7.559,0,0,1-5.267-5.6,2.978,2.978,0,0,0-.169-.738V23.319a.113.113,0,0,0,0-.176v-.176c.194-.091.107-.276.13-.417a14.195,14.195,0,0,1,4.382-8.33,1.061,1.061,0,0,1,.473-.329,1.121,1.121,0,0,1-.029.258,4.786,4.786,0,0,0-.255,1.461,12.9,12.9,0,0,0,.093,2.64,10.824,10.824,0,0,0,1.207,3.492.808.808,0,0,1,.075.7c-.316,1.01-.584,2.036-.866,3.057a4.109,4.109,0,0,1-.287,1.031A.319.319,0,0,0,5,26.913a.515.515,0,0,0,.355.238c.426.09.673-.3,1.063-.326a2.466,2.466,0,0,0,.722-.186,4.586,4.586,0,0,0,.562-.173,1.247,1.247,0,0,1,.6-.169c.2.01.233-.209.377-.158.239.086.418-.1.624-.139a1.3,1.3,0,0,1,.953-.005,11.081,11.081,0,0,0,1.445.678,9.652,9.652,0,0,0,2.828.673,11.226,11.226,0,0,0,2.581-.092,11.606,11.606,0,0,0,2.969-.914,9.911,9.911,0,0,0,2.361-1.518,12.886,12.886,0,0,0,1.593-1.645,10.949,10.949,0,0,0,1.193-1.955,7.511,7.511,0,0,0,.538-1.292,11.388,11.388,0,0,0,.5-3.9,14.025,14.025,0,0,0-.254-1.9,11.461,11.461,0,0,0-1.647-3.72c-.055-.089-.173-.137-.17-.262a3.961,3.961,0,0,0,1.914-1.6,4.218,4.218,0,0,0-.984-5.625,13.193,13.193,0,0,0-1.21-.693,1.555,1.555,0,0,1,.291.015,7.542,7.542,0,0,1,4.286,2.732,7.464,7.464,0,0,1,1.564,4.712c.016,5,.008,10.009,0,15.013a5.735,5.735,0,0,1-.065.924A7.531,7.531,0,0,1,24.8,31.706c-.47.148-.954.221-1.428.342"
                                transform="translate(-0.056 -2.052)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59377"
                                data-name="Tracé 59377"
                                d="M23.9.175a13.168,13.168,0,0,1,1.21.693A4.218,4.218,0,0,1,26.1,6.492a3.96,3.96,0,0,1-1.914,1.6.683.683,0,0,1-.176-.187,9.589,9.589,0,0,0-1.5-1.566,10.417,10.417,0,0,0-3.036-1.82,9.136,9.136,0,0,0-2.9-.692,10.553,10.553,0,0,0-1.7-.039,17.07,17.07,0,0,0-1.959.286A10.05,10.05,0,0,0,9.519,5.581a10.448,10.448,0,0,0-2.81,2.737,11.42,11.42,0,0,0-1.614,3.4l-.053.123,0,0a1.061,1.061,0,0,0-.473.329A14.195,14.195,0,0,0,.19,20.5c-.023.141.064.326-.13.417V6.624C.119,6.6.115,6.552.119,6.5A6.451,6.451,0,0,1,.745,4.332,7.478,7.478,0,0,1,3.551,1.149,6.928,6.928,0,0,1,7.271.014C12.424-.009,17.577,0,22.73.01a2.989,2.989,0,0,1,.938.121.363.363,0,0,0,.239.046Z"
                                transform="translate(-0.055 0)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59379"
                                data-name="Tracé 59379"
                                d="M.061,269.432a.113.113,0,0,1,0,.176Z"
                                transform="translate(-0.056 -248.34)"
                                fill="#00eb78"
                            />
                            <path
                                id="Tracé_59380"
                                data-name="Tracé 59380"
                                d="M60.182,56.075a11.422,11.422,0,0,1,1.614-3.4,10.449,10.449,0,0,1,2.81-2.737A10.051,10.051,0,0,1,68,48.43a17.09,17.09,0,0,1,1.959-.286,10.562,10.562,0,0,1,1.7.039,9.135,9.135,0,0,1,2.9.692,10.417,10.417,0,0,1,3.036,1.82,9.589,9.589,0,0,1,1.5,1.566.684.684,0,0,0,.176.187c0,.125.115.173.17.262a11.462,11.462,0,0,1,1.647,3.72,14.024,14.024,0,0,1,.254,1.9,11.385,11.385,0,0,1-.5,3.9,7.508,7.508,0,0,1-.537,1.292,10.954,10.954,0,0,1-1.193,1.955,12.883,12.883,0,0,1-1.593,1.645,9.909,9.909,0,0,1-2.361,1.518,11.609,11.609,0,0,1-2.969.914,11.223,11.223,0,0,1-2.581.092,9.646,9.646,0,0,1-2.828-.673,11.073,11.073,0,0,1-1.445-.678,1.3,1.3,0,0,0-.953.005c-.206.043-.384.224-.624.139-.144-.051-.18.167-.377.158a1.247,1.247,0,0,0-.6.169,4.575,4.575,0,0,1-.562.173,2.469,2.469,0,0,1-.722.187c-.39.024-.637.417-1.063.326a.514.514,0,0,1-.355-.238.319.319,0,0,1-.012-.383,4.106,4.106,0,0,0,.287-1.031c.282-1.021.55-2.046.866-3.057a.807.807,0,0,0-.075-.7,10.821,10.821,0,0,1-1.207-3.492,12.9,12.9,0,0,1-.093-2.64,4.783,4.783,0,0,1,.255-1.461,1.122,1.122,0,0,0,.029-.258l0,0c.061-.022.066-.068.053-.123m16.875-3.283a5.427,5.427,0,0,0-.648-.621,8.761,8.761,0,0,0-2.292-1.432,9.677,9.677,0,0,0-2.109-.6,7.688,7.688,0,0,0-2.622-.059,8.537,8.537,0,0,0-2.817.868,8.851,8.851,0,0,0-2.82,2.249,3.546,3.546,0,0,0-.645.944,4.811,4.811,0,0,0-.655,1.163,8.643,8.643,0,0,0-.745,3.686,10.59,10.59,0,0,0,.119,1.439,7.731,7.731,0,0,0,.92,2.622,1.663,1.663,0,0,1,.253,1.39c-.183.6-.359,1.2-.537,1.8-.081.274-.155.55-.241.822-.015.046-.147.039-.068.142a.173.173,0,0,0,.183.063c.433-.1.859-.227,1.292-.329.52-.122,1.032-.29,1.547-.443a1.122,1.122,0,0,1,.988.125,5.444,5.444,0,0,0,.867.432,8.334,8.334,0,0,0,2.779.725,9.007,9.007,0,0,0,2.256-.1,9.259,9.259,0,0,0,3.112-1.159,8.969,8.969,0,0,0,4.281-6.894,8.488,8.488,0,0,0-.477-3.7,7.862,7.862,0,0,0-.752-1.6,8.726,8.726,0,0,0-1.168-1.529"
                                transform="translate(-55.142 -44.355)"
                                fill="#fff"
                            />
                            <path
                                id="Tracé_59381"
                                data-name="Tracé 59381"
                                d="M301.63,1.516c.082,0,.176-.053.239.046a.363.363,0,0,1-.239-.046"
                                transform="translate(-278.018 -1.385)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59382"
                                data-name="Tracé 59382"
                                d="M63.749,149.716c.013.054.008.1-.053.123l.053-.123"
                                transform="translate(-58.71 -137.996)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59383"
                                data-name="Tracé 59383"
                                d="M99.113,107.719a8.726,8.726,0,0,1,1.168,1.529,7.862,7.862,0,0,1,.752,1.6,8.487,8.487,0,0,1,.477,3.7,8.969,8.969,0,0,1-4.281,6.894,9.261,9.261,0,0,1-3.112,1.159,9.006,9.006,0,0,1-2.256.1,8.334,8.334,0,0,1-2.779-.725,5.439,5.439,0,0,1-.867-.432,1.122,1.122,0,0,0-.988-.125c-.514.152-1.027.321-1.547.443-.433.1-.859.231-1.292.329a.173.173,0,0,1-.183-.063c-.079-.1.053-.1.068-.142.086-.272.16-.548.241-.822.178-.6.354-1.2.537-1.8a1.663,1.663,0,0,0-.253-1.39,7.731,7.731,0,0,1-.92-2.622,10.578,10.578,0,0,1-.119-1.439,8.644,8.644,0,0,1,.745-3.686,4.811,4.811,0,0,1,.655-1.163c.553-.205,1.1-.42,1.661-.615a12.381,12.381,0,0,1,3.98-.722c2.771-.028,5.542-.009,8.313-.01m-9.743.822c-.161.03-.35,0-.545.039-.392.078-.56.394-.786.644a2.146,2.146,0,0,0-.573,1.575,8.038,8.038,0,0,0,.22,1.471A9.039,9.039,0,0,0,89.12,115.4a9.365,9.365,0,0,0,1.668,1.77,7.628,7.628,0,0,0,1.566,1.008,8.631,8.631,0,0,0,2.375.766,4.481,4.481,0,0,0,1.021.124,2.948,2.948,0,0,0,1.595-.654,1.7,1.7,0,0,0,.6-1.737.42.42,0,0,0-.225-.311c-.67-.392-1.38-.7-2.073-1.049a.534.534,0,0,0-.683.138c-.214.252-.48.46-.7.711a.682.682,0,0,1-.738.211,4.666,4.666,0,0,1-2.806-1.988,4.972,4.972,0,0,1-.659-1.575.539.539,0,0,1,.152-.569q.4-.409.807-.808a.47.47,0,0,0,.119-.6c-.345-.692-.692-1.382-1.033-2.075a.368.368,0,0,0-.366-.226c-.117,0-.234,0-.379,0"
                                transform="translate(-77.199 -99.282)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59384"
                                data-name="Tracé 59384"
                                d="M115.64,74.84c-2.771,0-5.542-.018-8.313.01a12.38,12.38,0,0,0-3.98.722c-.557.2-1.107.409-1.661.615a3.547,3.547,0,0,1,.645-.944,8.852,8.852,0,0,1,2.82-2.249,8.537,8.537,0,0,1,2.817-.868,7.688,7.688,0,0,1,2.622.059,9.677,9.677,0,0,1,2.109.6,8.761,8.761,0,0,1,2.292,1.432,5.426,5.426,0,0,1,.648.621"
                                transform="translate(-93.726 -66.403)"
                                fill="#00d264"
                            />
                            <path
                                id="Tracé_59385"
                                data-name="Tracé 59385"
                                d="M133.023,118.278c.145,0,.262,0,.379,0a.368.368,0,0,1,.366.226c.342.693.689,1.383,1.033,2.075a.47.47,0,0,1-.119.6q-.408.4-.807.808a.539.539,0,0,0-.152.569,4.972,4.972,0,0,0,.659,1.575,4.667,4.667,0,0,0,2.806,1.988.682.682,0,0,0,.738-.211c.215-.251.481-.459.7-.711a.534.534,0,0,1,.683-.138c.693.345,1.4.657,2.073,1.049a.42.42,0,0,1,.225.311,1.7,1.7,0,0,1-.6,1.737,2.948,2.948,0,0,1-1.595.654,4.481,4.481,0,0,1-1.022-.124,8.632,8.632,0,0,1-2.375-.766,7.627,7.627,0,0,1-1.566-1.008,9.365,9.365,0,0,1-1.668-1.77,9.04,9.04,0,0,1-1.434-3.131,8.039,8.039,0,0,1-.22-1.471,2.146,2.146,0,0,1,.573-1.575c.225-.25.394-.566.786-.644.2-.039.384-.009.545-.039"
                                transform="translate(-120.851 -109.018)"
                                fill="#fff"
                            />
                        </g>
                    </svg>
                </a>
            </template>
        </template>

        <modal name="modal-plan" width="90%" height="90%" class="mcontact">
            <div class="mc-inner">
                <div class="mc-title">Contact</div>
                <div class="mc-container">
                    <div class="mcc-title">POUR PLUS D’INFORMATIONS SUR VOTRE FUTURE</div>
                    <div class="mcc-desc">VILLA Remplissez le formulaire ci-dessous</div>
                    <div class="mcc-row clearfix">
                        <div class="mcc-col">
                            <input type="text" placeholder="Nom*" />
                            <input type="text" placeholder="Prenom*" />
                            <input type="text" placeholder="Email*" />
                            <input type="text" placeholder="Code postal*" />
                            <input type="text" placeholder="Telephone*" />
                        </div>
                        <div class="mcc-col">
                            <textarea class="mcc-textarea" placeholder="Message*"></textarea>
                            <div class="mcci-row">
                                <span class="mcci-40"> Xj2z3T </span>
                                <input class="mcci-60" type="text" placeholder="Retepez ce code" />
                            </div>
                        </div>
                    </div>
                    <div class="mc-term">
                        <div class="mct-check">
                            <input type="checkbox" />
                            J’accepte d’être contacté(e) par un conseiller*
                        </div>
                        <div class="mct-desc">
                            *Champs obligatoires. Les informations collectées sont destinées à l’usage exclusif de
                            PROMEGE. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à
                            l’utilisation des données par ce formulaire, veuillez consulter nos mentions légales.
                        </div>
                    </div>
                    <div class="mc-wrap">
                        <div class="mc-submit">JE SOUHAITE ÊTRE RECONTACTÉ(E)</div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'AsideDescktopContacts',
    data() {
        return {
            isPhoneNumberVisible: false
        };
    },
    computed: {
        ...mapGetters({
            // Contacts
            enableContactSidebar: 'config/enableContactSidebar',

            telephoneNumber: 'config/telephoneNumber',
            whatsappNumber: 'config/whatsappNumber',
            contactSidebarBackground: 'config/contactSidebarBackground',
            enableContactForm: 'config/enableContactForm',
            isDarkTheme: 'theme/isDarkTheme',
            formAction: 'projectExtend/formAction',
            formAnchor: 'projectExtend/formAnchor'
        }),
        isContactDataExist() {
            return this.enableContactForm || this.telephoneNumber || this.whatsappNumber;
        },
        isScrollToFormAction() {
            return this.formAction === this.$constants.extendFormActions.SCROLL;
        },
        isOpenFormAction() {
            return this.formAction === this.$constants.extendFormActions.OPEN;
        }
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
            setFormAnchor: 'projectExtend/setFormAnchor',
            setFormAction: 'projectExtend/setFormAction'
        }),
        sanitizeNumber(phoneNumber) {
            return phoneNumber.replace(/[^0-9]/g, '');
        },
        onContactForm(evt) {
            if (this.formAction === this.$constants.extendFormActions.SCROLL) {
                this.$route.path !== '/' && this.$router.replace('/');
                this.hideMenuHandler();

                return;
            }
            evt.preventDefault();
            this.setIsContactModalActive(true);
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-contacts {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 100%;
    padding: 8px 15px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;

    &__item {
        position: relative;

        .menu-contact-button {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
        }

        .menu-contact-tel {
            display: flex;
            align-items: center;
            padding: 0 4px;
            font-size: 14px;
            color: #fff;
            font-family: 'Open Sans';
            transition: opacity 0.3s ease;
        }

        .menu-contact-tel:hover {
            color: #fff;
            opacity: 0.8;
        }
    }
}
</style>
