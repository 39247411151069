<template>
    <button
        v-if="pointOfInterestFilter || floorsFilter"
        class="toggle-all-hotspots"
        @click="togglePointOfInterest"
    >
        <slot />
    </button>
</template>

<script>
import scheme3dMenuFiltersMixin from '@front/mixins/scheme3dMenuFiltersMixin';

export default {
    name: 'ToggleAllHotspots',
    mixins: [scheme3dMenuFiltersMixin],
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        togglePointOfInterest() {
            this.isActive = !this.isActive;

            const filters = [];

            if (this.pointOfInterestFilter) {
                const options = this.pointOfInterestFilter.options.map((option) => ({...option, checked: this.isActive}));
                filters.push({...this.pointOfInterestFilter, options})
            }
            
            if (this.floorsFilter) {
                const options = this.floorsFilter.options.map((option) => ({...option, checked: this.isActive}));
                filters.push({...this.floorsFilter, options})
            }

            this.updateFilters(filters);
        },
    },
};
</script>

<style lang="scss" scoped>
.toggle-all-hotspots {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Open Sans';
    line-height: 1;
    cursor: pointer;
    text-decoration: underline;
}
</style>
