<template>
    <div class="form-wrapper">
        <div class="ct-title">
            <div class="ct-title-top">{{ $t('Pour être contacté(e)') }}</div>
            <div class="ct-title-bot">{{ $t('renseignez vos coordonnées') }}</div>
        </div>
        <form class="contact-form" @submit.prevent="sendForm">
            <div class="contact-form" v-if="!isFormSuccess">
                <div class="input-columns">
                    <div class="col-first">
                        <div class="col-label">{{ $t('Nom*') }}</div>
                        <input v-model="contactModel.name" class="form-input" type="text" :placeholder="$t('Nom*')"
                            required />
                    </div>
                    <div class="col-second">
                        <div class="col-label">{{ $t('Prénom') }}</div>
                        <input v-model="contactModel.surname" class="form-input" type="text"
                            :placeholder="$t('Prénom')" required />
                    </div>
                </div>
                <div class="input-columns">
                    <div class="col-first">
                        <div class="col-label">{{ $t('Code postal') }}</div>
                        <input v-model="contactModel.postalCode" class="form-input" type="text"
                            :placeholder="$t('Code postal')" required />
                    </div>
                    <div class="col-second">
                        <div class="col-label">{{ $t('Téléphone') }}</div>
                        <vue-tel-input
                            class="telephone"
                            id="phone"
                            v-model="contactModel.phone"
                            placeholder="Téléphone"
                            :class="{ 'is-invalid': !isPhoneValid }"
                            @validate="isValidPhone"
                        />
                    </div>
                </div>
                <div class="input-columns">
                    <div class="col-full">
                        <div class="col-label">{{ $t('Email*') }}</div>
                        <input v-model="contactModel.mail" class="form-input" type="text"
                            :placeholder="$t('Email*')" required />
                    </div>
                </div>
                <div class="input-columns">
                    <div class="col-full">
                        <div class="captcha-row">
                            <img class="c-img" :src="captcha" alt="" />
                            <input v-model="contactModel.captcha" class="form-input captcha-input" type="text"
                                :placeholder="$t('Retapez ce code')" required />
                            <button type="button" class="captcha__refresh" @click="getCaptcha" />
                        </div>
                    </div>
                </div>
                <div class="checkbox">
                    <label class="custom-checkbox">
                        {{ $t('J’accepte d’être contacté(e) par un conseiller SOVALIM*') }}
                        <input v-model="agreement" type="checkbox" name="agree_from" required />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="champ-obligations">
                    {{ $t('*Champs obligatoires. Les informations collectées sont destinées à l’usage exclusif de SOVALIM. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à l’utilisation des données par ce formulaire, veuillez consulter nos mentions légales.')}}
                </div>
                <div class="btn-container" style="display: flex; justify-content: center">
                    <button class="link-btn" type="submit" :disabled="isSendingInProgress">{{ $t('JE SOUHAITE ÊTRE CONTACTÉ(E)') }}</button>
                </div>
            </div>
            <div v-else>
                <div class="contact-success">{{ $t('Merci. Votre demande a bien été prise en compte. Vous allez recevoir un message de confirmation') }}</div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import clone from 'lodash.clonedeep'
import { mapActions, mapMutations, mapGetters } from 'vuex'

const PIECES = [
    { value: 1, label: 'STUDIO' },
    { value: 2, label: '2 PIÈCES' },
    { value: 3, label: '3 PIÈCES' },
    { value: 4, label: '3 PIÈCES ÉVOLUTIFS' },
    { value: 5, label: '4 PIÈCES' },
    { value: 6, label: '5 PIÈCES' },
    { value: 7, label: '5 PIÈCES DUPLEX' }
]

const initialModel = {
    name: '',
    surname: '',
    mail: '',
    postalCode: '',
    phone: '',
    message: '',
    investType: 'HABITER',
    captcha: null,
    lotRooms: PIECES[0]
}

export default {
    name: 'ContactForm',
    data() {
        return {
            contactModel: clone(initialModel),
            isPhoneValid: true,
            agreement: false,
            isSelectOpen: false,
            isFormFilling: false,
            isSendingInProgress: false,
            isFormSuccess: false,
        }
    },
    computed: {
        ...mapGetters({
            captcha: 'captcha/captcha'
        }),
        piecesOptions() {
            return PIECES
        }
    },
    mounted() {
        this.getCaptcha()
    },
    methods: {
        ...mapActions({
            getCaptcha: 'captcha/getCaptcha'
        }),
        ...mapMutations({
            updateContactFormModalProps: 'projectExtend/updateContactFormModalProps'
        }),
        updateContactFormSuccess() {
            this.isFormSuccess = true
        },
        async sendForm() {
            if (!this.agreement) {
                return
            }

            if (this.isSendingInProgress) {
                return
            }

            if (!navigator.onLine) {
                this.$notify({
                    type: 'error',
                    title: 'Envoi non possible, connexion internet hors service',
                    text: 'Veuillez vérifier votre connexion.',
                });

                return;
            }
            this.isFormSubmitting = true;

            try {
                this.isSendingInProgress = true
                const payload = clone(this.contactModel)
                payload.lotRooms = this.contactModel.lotRooms.value
                await axios.post('/api/callback/landing-form', payload)
                window.criteo_q = window.criteo_q || []
                const deviceType = /iPad/.test(navigator.userAgent)
                    ? 't'
                    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd'
                window.criteo_q.push(
                    { event: 'setAccount', account: 100226 },
                    { event: 'setEmail', email: `${this.contactModel.mail}`, hash_method: 'sha256' },
                    { event: 'setSiteType', type: deviceType },
                    { event: 'setZipcode', zipcode: `${this.contactModel.postalCode}` },
                    { event: 'Homepage' }
                )
                this.updateContactFormModalProps({ modal: 'success', prop: 'isActive', value: true })
                this.updateContactFormSuccess()
                this.contactModel = clone(initialModel)
                this.agreement = false
                gtag('event', 'submit', {
                    event_category: 'formulaire_contact',
                    event_label: 'formulaire_contact'
                })
                gtag('event', 'conversion', { send_to: 'AW-10941698271/dRWDCP6sx8oDEN-htOEo' })
                fbq('track', 'Lead')
                typeof window.lintrk === 'function' && window.lintrk('track', { conversion_id: 12000026 })
                this.$emit('send');
            } catch (error) {
                console.error(error)
                this.contactModel.captcha = null
                if (error.response?.data?.error.includes('Le code saisi est incorrect')) {
                    this.$notify({
                        type: 'error',
                        title: 'Envoi non possible',
                        text: error.response?.data?.error ?? ''
                    })
                } else {
                    this.updateContactFormModalProps({ modal: 'error', prop: 'isActive', value: true })
                }
            } finally {
                this.getCaptcha()
                this.isSendingInProgress = false
            }
        },
        analyticsEvent() {
            if (this.isFormFilling) {
                return
            }

            gtag('event', 'submit', {
                event_category: 'start_fill_form',
                event_label: 'start_fill_form'
            })

            this.isFormFilling = true
        },
        isValidPhone({valid}) {
            this.isPhoneValid = valid;
        },
    }
}
</script>

<style>
.vti__dropdown {
    padding: 0px 5px;
    border-radius: 10px;
}
.form-wrapper .telephone {
    border: solid 1px #000 !important;
    border-radius: 10px !important;
}
.form-wrapper .contact-form input, .form-wrapper .contact-form textarea {
    min-width: auto;
    margin-bottom: 0px;
    height: auto;
    line-height: 1px;
    font-size: 12px;
    padding: 14px 5px;
    border-left: solid 1px #000 !important;
}
.contact-success {
    line-height: 1.2;
}
.link-btn {
    position: relative;
    background-color: #000;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    letter-spacing: 2px;
}

.link-btn:disabled, .link-btn[disabled] {
    cursor: not-allowed;
}

.loading::before, .link-btn[disabled]::before  {
    content: "";
    position: absolute;
    left: 8px;
    top: 8px;
    margin: auto;
    border: 3px solid #EAF0F6;
    border-radius: 100%;
    border-top: 3px solid #4e85db;
    width: 30px;
    height: 30px;
    line-height: 0px;
    padding: 0px;
    animation: spinner 2s linear infinite;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
<style lang="scss" scoped>
@import '../assets/styles/customform.scss';

.form-wrapper .contact-form .input-columns .form-input.telephone {
    padding-left: 65px;
}
.telephone-nation {
    width: 35px;
    height: 20px;
    position: absolute;
    background-size: cover;
    margin-left: 10px;
    margin-top: 12px;
}

.telephone-nation:after {
    position: absolute;
    content: '';
    height: 43px;
    width: 1px;
    background-color: #aaa;
    margin-top: -11px;
    margin-left: 25px;
}
</style>
