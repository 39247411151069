<template>
    <div>
        <transition name="lot-info">
            <div
                v-if="lotInfo"
                class="scheme-lot-info--wrapper"
            >
                <div class="scheme-lot-info--container">
                    <div>
                        <p
                            class="lot-info__number"
                            :style="{color: hotspotColor(lotInfo)}"
                        >
                            <span>{{ lotInfo.lot }}</span>
                        </p>
                        <div class="scheme-lot-info__space">
                            <p v-if="lotInfo.area">
                                {{ lotInfo.rooms }} - {{ lotInfo.area }}&nbsp;M
                                <sup>2</sup>
                            </p>
                        </div>
                        <p v-if="isPriceFilterAvailable && additionalLotInfo.price">
                            (Prix annexe {{ lotInfo.taxIncluded ? 'inclus' : 'non inclus' }})
                        </p>
                        <template v-if="isPriceFilterAvailable">
                            <div
                                v-if="additionalLotInfo.priceData.length"
                                class="scheme-lot-info__space"
                            >
                                <template v-for="(priceData, index) in additionalLotInfo.priceData">
                                    <p
                                        v-if="isPrice(priceData.value)"
                                        :key="priceData.tvaCode + index"
                                        class="grid-price"
                                    >
                                        <span>{{ priceData.labelShort }}{{ priceData.tvaLabel }}</span>
                                        <span>: {{ priceFormat(priceData.value, priceData.unit) }}</span>
                                    </p>
                                </template>
                            </div>
                            <div
                                v-if="!additionalLotInfo.priceData.length && isPrice(additionalLotInfo.price)"
                                class="scheme-lot-info__price"
                            >
                                <p :style="{color: hotspotColor(lotInfo)}">
                                    {{ priceFormat(getPrice(additionalLotInfo)) }}
                                </p>
                            </div>
                        </template>
                    </div>
                    <div class="scheme-lot-info--container--buttons">
                        <template v-if="additionalLotInfo">
                            <div class="choose-form">
                                <div
                                    id="toggle-plan"
                                    class="toggles toggles-plan"
                                    :class="{active: activeToggle === 'toggle-plan'}"
                                    @click="
                                        toggleImage(false);
                                        activeToggle = 'toggle-plan';
                                    "
                                >
                                    PLAN
                                </div>
                                <div
                                    v-if="enableAxonometricLot && lotInfo.image"
                                    id="toggle-axon"
                                    class="toggles toggles-axon"
                                    :class="{active: activeToggle === 'toggle-axon'}"
                                    @click="
                                        toggleImage(true);
                                        activeToggle = 'toggle-axon';
                                    "
                                >
                                    AXONOMÉTRIE
                                </div>
                            </div>
                            <div
                                v-if="isEmployee && enableCreatingLotInfoForm"
                                class="form-modal-wrap"
                            >
                                <template v-if="additionalLotInfo.status === $constants.statuses.DISPO">
                                    <vSelect
                                        v-model="email"
                                        label="email"
                                        :placeholder="isPlaceholder ? 'ADRESSE EMAIL' : 'Données non renseignées'"
                                        :options="contacts"
                                        :value="softbookingModel.contact.mail"
                                        :class="{errorMsg: !isPlaceholder && email === null}"
                                        @search="getContactsList"
                                        @input="setContact"
                                        @input.native="setContactMail"
                                    />
                                    <input
                                        v-model="softbookingModel.contact.nom"
                                        :placeholder="isPlaceholder ? 'NOM' : 'Données non renseignées'"
                                        :class="[
                                            'scheme-lot-input',
                                            {errorMsg: !isPlaceholder && !softbookingModel.contact.nom},
                                        ]"
                                        type="text"
                                    />
                                    <input
                                        v-model="softbookingModel.contact.prenom"
                                        :placeholder="isPlaceholder ? 'PRÉNOM' : 'Données non renseignées'"
                                        :class="[
                                            'scheme-lot-input',
                                            {errorMsg: !isPlaceholder && !softbookingModel.contact.prenom},
                                        ]"
                                        type="text"
                                    />
                                    <vue-tel-input
                                        v-model="softbookingModel.contact.telephone"
                                        :class="{errorMsg: !isPlaceholder && softbookingModel.contact.telephone === ''}"
                                        v-bind="phoneProps"
                                        @open="isOpenTelDropdown = true"
                                        @close="isOpenTelDropdown = false"
                                    >
                                        <template #arrow-icon>
                                            <span>{{ isOpenTelDropdown ? '▲' : '▼' }}</span>
                                        </template>
                                    </vue-tel-input>
                                    <vSelect
                                        :placeholder="
                                            isPlaceholder
                                                ? 'Taper la ville de votre contact'
                                                : 'Données non renseignées'
                                        "
                                        label="label"
                                        :class="[
                                            'mt-20',
                                            {errorMsg: !isPlaceholder && !softbookingModel.contact.cityLabel},
                                        ]"
                                        :options="cities"
                                        :value="softbookingModel.contact.cityLabel"
                                        @search="getCitiesList"
                                        @input="setCity"
                                        @input.native="setContactCity"
                                    />
                                </template>
                                <div
                                    class="flex flex-column justify-enf text-center"
                                    style="min-height: 60px"
                                >
                                    <p
                                        v-if="additionalLotInfo.status === $constants.statuses.OPTION"
                                        class="btn-optoin-info"
                                    >
                                        <span>LOT OPTIONNÉ</span>
                                        - {{ descrBooking }} {{ additionalLotInfo.bookingEnds }}
                                    </p>
                                    <button
                                        :class="[
                                            'scheme-lot-info__btn-optoin flex items-center',
                                            {backgroundBlock: statusLotBlock, backgroundGreen: statusLotMaster},
                                        ]"
                                        :disabled="isButtonDisabled"
                                        @click="softbookingExamination"
                                    >
                                        {{ btnName }}
                                        <div
                                            v-if="isSpinner"
                                            class="spinner"
                                        />
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div class="scheme-lot-info__buttons">
                            <div
                                v-if="!statusCheck"
                                class="scheme-lot-info__btn"
                                @click="addToFavorites"
                            >
                                {{ !favorites.includes(lotInfo) ? 'AJOUTER À MES CHOIX' : 'SUPPRIMER DE MES CHOIX' }}
                            </div>
                            <div
                                v-if="enableLotsComparison"
                                class="scheme-lot-info__btn off-element"
                                @click="toggleInfo"
                            >
                                optionner ce lot
                            </div>
                            <a
                                v-if="lotInfo.document"
                                :href="getMediaPath(lotMediaPath, lotInfo.color, lotInfo.document)"
                                target="_blank"
                                class="scheme-lot-info__btn"
                                download
                            >
                                TÉLÉCHARGER LE PLAN
                            </a>
                        </div>
                    </div>
                </div>
                <div class="scheme-lot-info__img">
                    <img
                        v-if="isImage"
                        :src="lotInfo.image ? getMediaPath(lotMediaPath, lotInfo.color, lotInfo.image) : defaultImage"
                        :alt="altImgText"
                    />
                    <template v-else>
                        <GallerySlider
                            v-if="$isMobile()"
                            :slides="slides"
                            class="scheme-lot-info-slider"
                        />
                        <object
                            v-else
                            width="100%"
                            height="100%"
                            :data="
                                lotInfo.document && !lotInfo.document.startsWith('default')
                                    ? getMediaPath(lotMediaPath, lotInfo.color, lotInfo.document)
                                    : defaultPdf
                            "
                            type="application/pdf"
                        />
                    </template>
                </div>
                <div class="scheme-lot-list__close">
                    <button
                        type="button"
                        class="btn-close"
                        @click="close"
                    >
                        <img
                            v-if="!isDarkTheme"
                            :src="$getImagePath('icons/icon-close-black.svg')"
                            :alt="altImgText"
                        />
                        <img
                            v-else
                            :src="$getImagePath('icons/icon-close.svg')"
                            :alt="altImgText"
                        />
                    </button>
                    <!-- <FullscreenToggle /> -->
                </div>
            </div>
        </transition>
        <ModalSchemeLotInfo
            v-if="isInfoVisible"
            :lot="lotInfo"
            @close="toggleInfo"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import clone from 'lodash.clonedeep';
import moment from 'moment';
import ModalSchemeLotInfo from '@front/components/Scheme3dLotInfo/ModalSchemeLotInfo.vue';
// import FullscreenToggle from '@front/components/Navigation/FullscreenToggle';
import softbookingModel from '@front/models/softbookingModel';
import {priceFormat} from '@front/utils/priceFormat';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import {getMediaPath} from '@front/utils/getMediaPath';
import priceLotsMixin from '@front/mixins/priceLotsMixin';
import GallerySlider from '@front/components/Slider/GallerySlider';

export default {
    name: 'SchemeLotInfo',
    components: {
        // FullscreenToggle,
        ModalSchemeLotInfo,
        vSelect,
        GallerySlider,
    },
    mixins: [menuOffTimeoutMixin, priceLotsMixin],
    data: () => ({
        email: null,
        isSpinner: false,
        isButtonDisabled: false,
        isPlaceholder: true,
        defaultPdf: './data/project/scheme_module/uploads/default/lots/plan.pdf',
        defaultImage: './images/lot_default.png',
        currentView: 0,
        isInfoVisible: false,
        activeToggle: 'toggle-plan',
        softbookingModel: clone(softbookingModel),
        isOpenTelDropdown: false,
        phoneProps: {
            defaultCountry: 'FR',
            dropdownOptions: {
                showFlags: true,
                showDialCodeInSelection: true,
            },
            inputOptions: {
                placeholder: 'Téléphone du contact',
            },
        },
    }),
    computed: {
        ...mapGetters({
            lotInfo: 'scheme/lotInfo',
            lotsData: 'scheme/lotsData',
            favorites: 'scheme/favorites',
            enableLotsComparison: 'scheme/enableLotsComparison',
            enableAxonometricLot: 'scheme/enableAxonometricLot',
            programId: 'scheme/programId',
            lotMediaPath: 'scheme/lotMediaPath',
            enableCreatingLotInfoForm: 'scheme/enableCreatingLotInfoForm',
            isPriceFilterAvailable: 'scheme/isPriceFilterAvailable',
            cities: 'externalApi/cities',
            contacts: 'externalApi/contacts',
            isEmployee: 'externalApi/isEmployee',
            altImgText: 'config/altImgText',
            isDarkTheme: 'theme/isDarkTheme',
        }),
        slides() {
            if (this.lotInfo.documentImages?.length) {
                return this.lotInfo.documentImages.map((image) => {
                    return {
                        path: getMediaPath(this.lotMediaPath, this.lotInfo.color, image),
                    };
                });
            }

            return [this.defaultImage];
        },
        additionalLotInfo() {
            return this.lotsData.find((x) => x.lot === this.lotInfo.lot);
        },
        statusCheck() {
            return this.additionalLotInfo.status === this.$constants.statuses.OPTION;
        },
        statusLotMaster() {
            if (this.additionalLotInfo.status === this.$constants.statuses.OPTION && this.additionalLotInfo.canEdt) {
                return true;
            }

            return false;
        },
        statusLotBlock() {
            if (this.additionalLotInfo.status === this.$constants.statuses.OPTION && !this.additionalLotInfo.canEdt) {
                return true;
            }

            return false;
        },
        isImage: {
            get() {
                return this.activeToggle === 'toggle-axon';
            },
            set(val) {
                this.activeToggle = val;
            },
        },
        isSoftbookingDisallow() {
            return (
                !this.softbookingModel.contact.mail ||
                !this.softbookingModel.contact.nom ||
                !this.softbookingModel.contact.prenom ||
                !this.softbookingModel.contact.telephone ||
                !this.softbookingModel.contact.cityLabel
            );
        },
        btnName() {
            if (this.statusLotMaster) {
                return 'LIBÉRER';
            }
            if (this.statusLotBlock) {
                return 'VOUS NE POUVEZ PAS LIBÉRER CE LOT';
            }

            return 'OPTIONNER CE LOT';
        },
        descrBooking() {
            return this.additionalLotInfo.canEdt ? 'Expire le' : 'FIN DE L’OPTION LE';
        },
    },
    created() {
        this.init();
    },
    methods: {
        getMediaPath,
        priceFormat,
        ...mapMutations({
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            changeIsMenuMustBeStatic: 'navigation/changeIsMenuMustBeStatic',
            setLotInfo: 'scheme/setLotInfo',
            changeIsMenuMustBeHidden: 'navigation/changeIsMenuMustBeHidden',
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
        }),
        ...mapActions({
            updateFavorites: 'scheme/updateFavorites',
            clearLotStatus: 'externalApi/clearLotStatus',
            createSoftbooking: 'externalApi/createSoftBooking',
            getCitiesList: 'externalApi/getCitiesList',
            getContactsList: 'externalApi/getContactsList',
            getExternalLotsData: 'externalApi/getExternalLotsData',
        }),
        init() {
            if (!this.isEmployee) {
                return;
            }

            const now = moment(new Date(), 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ss');
            const end = this.additionalLotInfo?.bookingEnds
                ? moment(this.additionalLotInfo.bookingEnds, 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')
                : '';

            if (end && !moment(now).isSameOrBefore(end)) {
                this.isButtonDisabled = true;
                this.isSpinner = true;
                this.patchLotStatus();
            }
        },
        setCity(data) {
            this.softbookingModel.contact.cityId = data?.id ?? null;
            this.softbookingModel.contact.cityLabel = data?.label ?? null;
        },
        setContactMail({target: {value}}) {
            this.softbookingModel.contact.mail = value || null;
        },
        setContactCity({target: {value}}) {
            this.softbookingModel.contact.cityLabel = value || null;
        },
        setContact(data) {
            this.softbookingModel.contact.mail = data?.email ?? null;
            this.softbookingModel.contact.nom = data?.firstname ?? null;
            this.softbookingModel.contact.prenom = data?.lastname ?? null;
            this.softbookingModel.contact.telephone = data?.phone ?? '';
        },
        hotspotColor(lot) {
            if (!lot) {
                return this.$constants.defaultLotColor;
            }

            return lot.filterColor || `#${lot.color}`;
        },
        toggleInfo() {
            this.isInfoVisible = !this.isInfoVisible;
        },
        close() {
            this.toggleActivityAdditionalWindow();
            this.changeIsMenuMustBeStatic(false);
            this.setLotInfo(null);
            this.changeIsMenuMustBeHidden(false);
            this.hideMenuHandler();
            this.setIsCloseBurgerButtonHide(false);
        },
        toggleImage(isImage) {
            this.isImage = isImage;
        },
        addToFavorites() {
            this.updateFavorites(this.lotInfo);
        },
        async softbooking() {
            if (!this.isSoftbookingDisallow) {
                this.$notify({
                    type: 'error',
                    title: 'Cette opération n’a pas pu être effectuée',
                });

                return;
            }

            this.create(this.$constants.statuses.OPTION);
        },
        async softbookingExamination() {
            this.isButtonDisabled = true;
            if (this.statusLotBlock) {
                return;
            }

            if (!this.statusLotMaster && !this.statusLotBlock) {
                this.create(this.$constants.statuses.OPTION);

                return;
            }

            this.isSpinner = true;
            this.patchLotStatus();
        },
        async create(status) {
            if (this.additionalLotInfo.status !== this.$constants.statuses.OPTION && this.isSoftbookingDisallow) {
                this.isPlaceholder = false;
                this.isButtonDisabled = false;

                return;
            }

            this.isSpinner = true;
            this.softbookingModel.program = String(this.programId);
            this.softbookingModel.lot = this.additionalLotInfo.id;

            await this.createSoftbooking(this.softbookingModel);
            await this.getExternalLotsData();

            this.softbookingModel = clone(softbookingModel);
            this.isSpinner = false;
            this.isPlaceholder = true;
            this.isButtonDisabled = false;
        },
        async patchLotStatus() {
            const payload = {
                program: String(this.programId),
                lot: this.additionalLotInfo.id,
            };

            await this.clearLotStatus(payload).then((response) => {
                if (response.status === 204) {
                    this.$notify({
                        title: 'Opération réalisée avec succès',
                        type: 'success',
                    });
                }
            });

            if (
                this.softbookingModel.contact.mail ||
                this.softbookingModel.contact.nom ||
                this.softbookingModel.contact.prenom ||
                this.softbookingModel.contact.telephone
            ) {
                this.softbookingModel = clone(softbookingModel);
            }

            await this.getExternalLotsData();

            this.isSpinner = false;
            this.isPlaceholder = true;
            this.isButtonDisabled = false;
        },
    },
};
</script>

<style scoped>
.items-center {
    text-align: center;
    align-items: center;
    justify-content: center;
}
.toggles {
    color: white;
    cursor: pointer;
}
.form-modal-wrap {
    width: 100%;
    position: relative;
}
.scheme-lot-input {
    border: none;
    border-bottom: 1px solid #29b400;
    width: 100%;
    margin: 15px 0;
}
:deep(.vs__dropdown-toggle) {
    border: none;
    border-bottom: 1px solid;
}
:deep(.vti__dropdown-list) {
    z-index: 5;
}
.backgroundBlock {
    background: #cccccc;
    cursor: not-allowed;
}
.backgroundGreen {
    position: relative;
    background: green;
    cursor: pointer;
}
.btn-optoin-info {
    font: normal normal normal 11px/14px Lato;
    letter-spacing: 0.24px;
    text-align: center;
    color: #000;
    margin: 10px 0;
    text-transform: uppercase;
    opacity: 1;
}
.btn-optoin-info span {
    font: normal normal bold 13px/14px Lato;
    letter-spacing: 0.28px;
}
.errorMsg {
    border: 1px solid red !important;
}
.spinner {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner:after {
    animation: changeContent 0.8s linear infinite;
    display: block;
    content: '⠋';
    font-size: 30px;
}

@keyframes changeContent {
    10% {
        content: '⠙';
    }
    20% {
        content: '⠹';
    }
    30% {
        content: '⠸';
    }
    40% {
        content: '⠼';
    }
    50% {
        content: '⠴';
    }
    60% {
        content: '⠦';
    }
    70% {
        content: '⠧';
    }
    80% {
        content: '⠇';
    }
    90% {
        content: '⠏';
    }
}
</style>

<style lang="scss" scoped>
.scheme-lot-info-slider {
    position: relative;
}
</style>
