<template>
    <div>
        <div class="wide-image-wrap" @click="onToggleFullscreen">
            <div id="scroll-container" ref="scrollContainer" class="wide-image" @mousemove="calculateMousePercentage"
                @mouseleave="onMouseLeave">
                <img src="../assets/images/main.webp" alt="Wide Image">
            </div>
            <div class="title-wrap">
                <div class="title-inner">
                    <div class="title-top">
                        {{$t("PRÉPAREZ-VOUS À VIVRE L’EXCEPTIONNEL AU COEUR DE VAL D’ISÈRE")}}
                    </div>
                    <div class="title-bot2">
                        {{$t("PRÉPAREZ-VOUS À VIVRE L’EXCEPTIONNEL AU COEUR DE VAL D’ISÈRE")}}
                    </div>
                    <div class="title-bot">
                        {{$t("9 appartements de luxe à vendre en Savoie, offrant des prestations au confort inégalé. À quelques pas des pistes et de la mythique face de Bellevarde")}}
                    </div>
                </div>
            </div>
            <div class="hover15 figure">
                <div class="fullscreen">
                    <img src="../assets/images/fullscreen-ico.png" />
                    {{$t('Agrandir le visuel')}}
                </div>
            </div>
        </div>
        <div class="popup">
            <div class="wide-image-popup" :class="fullscreen ? 'active': ''" @click="onToggleFullscreen">
                <div class="popup-inner">
                    <div class="popup-close">
                        {{$t('Fermer')}}
                        <img src="../assets/images/close.png" />
                    </div>
                    <img @click.stop="" src="../assets/images/main.webp" alt="Wide Image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const SPEED = 1
export default {
    data() {
        return {
            scrollSpeed: SPEED,
            scrollSpeedSave: SPEED,
            mousePercentage: 0, // Adjust this to control the speed
            fullscreen: false
        }
    },
    mounted() {
        this.autoScroll();
    },
    methods: {
        addLanguageParam() {
            const currentUrl = new URL(window.location.href);
            const lang = this.$i18n.locale; // Assuming you're using vue-i18n
            currentUrl.searchParams.set('lang', lang);
            window.history.replaceState({}, '', currentUrl.toString());
        },
        calculateMousePercentage(event) {
        },
        onMouseLeave() {
            this.mousePercentage = 0
            this.scrollSpeed = this.scrollSpeedSave
        },
        onToggleFullscreen() {
            this.fullscreen = !this.fullscreen
        },
        autoScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const delta = this.scrollSpeed * 10
            scrollContainer.scrollLeft = delta
            const step = () => {
                scrollContainer.scrollLeft += this.scrollSpeed;
                if (scrollContainer.scrollLeft <= 0 || scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth - delta) {
                    this.scrollSpeed = -this.scrollSpeed
                    scrollContainer.scrollLeft += this.scrollSpeed
                }

                if (this.mousePercentage == 0) {
                    this.scrollSpeedSave = this.scrollSpeed
                }
                window.requestAnimationFrame(step)
            }
            window.requestAnimationFrame(step)
        }
    }
}
</script>

<style scoped>
.title-wrap {
    font-family: 'Lato';
    padding-bottom: 100px;
    text-align: center;
}
.title-bot {
    width: 640px;
    color: #fff;
    margin: auto;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
}
.title-bot2 {
  width: 640px;
  color: #fff;
  margin: auto;
  text-align: center;
  font-size: 23px;
  line-height: 1.4;
  font-weight: bolder;
}
.wide-image-wrap {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 760px;
    overflow: hidden;
}

.wide-image {
    position: absolute;
    overflow: hidden;
    width: 1300px;
    max-width: 100%;
    height: 100%;
}

.wide-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wide-image::-webkit-scrollbar {
    display: none;
}

.title-wrap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-right: 510px;
    z-index: 2;
}

.title-inner {
    font-family: Helvetica, Regular;
    padding-top: 40px;
    padding-bottom: 150px;
    text-align: center;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), transparent); */
}

.title-top {
    font-family: 'carla-sans';
    font-weight: bolder;
    display: none;
    width: 640px;
    color: #fff;
    margin: auto;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 1.4;
    letter-spacing: 3px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
}

.title-bot {
    width: 480px;
    color: #fff;
    margin-right: 40px;
    text-align: right;
    font-size: 18px;
    line-height: 1.4;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.title-bot2 {
  width: 480px;
  color: #fff;
  margin-right: 40px;
  text-align: right;
  font-size: 23px;
  line-height: 1.4;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.wide-image-wrap:after {
    content: '';
    position: absolute;
    background-image: linear-gradient(#2317609b, transparent);
    background-color: transparent;
    width: 1300px;
    max-width: 100%;
    height: 220px;
    max-height: 100%;
    z-index: 0;
}
.hover15.figure {
	position: absolute;
    width: 1300px;
    max-width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
}
.hover15.figure::before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(0,0,0,0.7);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 1;
}
.wide-image-wrap .hover15.figure::before {
    -webkit-animation: circle-out 0.5s;
	animation: circle-out 0.5s;
    width: 0px;
    height: 0px;
}
.wide-image-wrap:hover .hover15.figure::before {
	-webkit-animation: circle 0.5s;
	animation: circle 0.5s;
    width: 100%;
    height: 100%;
}
.fullscreen {
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    width: 300px;
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: underline;
}
.fullscreen img {
    margin-right: 15px;
}
.wide-image-wrap:hover .fullscreen {
    display: flex;
}
.wide-image-popup {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: space-around;
    display: none;
}
.wide-image-popup.active {
    display: flex;
}
.wide-image-popup img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.popup-inner {
    display: block;
    position: relative;
    margin: auto;
    width: 90%;
    height: 90%;
}
.popup-close {
    position: absolute;
    right: 0px;
    display: flex;
    color: #fff;
    text-transform: uppercase;
    padding-top: 20px;
    padding-right: 20px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
}
.popup-close>img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

@-webkit-keyframes circle {
	0% {
        width: 200px;
        height: 200px;
		opacity: 0;
        border-radius: 100%;
	}
	40% {
		opacity: 1;
        border-radius: 100%;
	}
	100% {
        border-radius: 0px;
		width: 2000px;
		height: 2000px;
		opacity: 1;
	}
}
@keyframes circle {
	0% {
        width: 200px;
        height: 200px;
		opacity: 0;
        border-radius: 100%;
	}
	40% {
		opacity: 1;
        border-radius: 100%;
	}
	100% {
        border-radius: 0px;
		width: 2000px;
		height: 2000px;
		opacity: 1;
	}
}
@-webkit-keyframes circle-out {
	0% {
        border-radius: 0px;
		width: 2000px;
		height: 2000px;
		opacity: 1;
	}
	40% {
		opacity: 1;
        border-radius: 100%;
	}
	100% {
        width: 200px;
        height: 200px;
		opacity: 0;
        border-radius: 100%;
	}
}
@keyframes circle-out {
	0% {
        border-radius: 0px;
		width: 2000px;
		height: 2000px;
		opacity: 1;
	}
	40% {
		opacity: 1;
        border-radius: 100%;
	}
	100% {
        width: 200px;
        height: 200px;
		opacity: 0;
        border-radius: 100%;
	}
}

@media (max-width: 1300px) {
    .title-wrap {
        padding-right: 0px;
    }

    .wide-image-wrap {
        height: 680px;
    }
    .wide-image {
        width: 100%;
    }

    .wide-image img {
        width: auto;
        height: 100%;
        object-fit: contain;
    }

    .title-top {
        width: 256px;
        font-size: 16px;
        display: block;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
    }

    .title-bot {
        width: 300px;
        font-size: 16px;
        margin: auto;
        text-align: center;
    }
    .title-bot2 {
      display: none;
    }
}
</style>
