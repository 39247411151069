import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Messages for different languages
function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)/i);
        if (matched && matched.length > 1) {
            const [locale] = matched;
            messages[locale] = locales(key);
        }
    });

    return messages;
}

// Create VueI18n instance with options
const userLang = navigator.language || navigator.userLanguage;
const [defaultLang] = userLang.split('-');

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || defaultLang || 'fr',
    fallbackLocale: 'fr',
    messages: loadLocaleMessages(), // set locale messages
});

export default i18n;
