<template>
    <div class="d-flex flex-row align-items-start">
        <template v-for="menuItem in menuRoutes">
            <router-link
                v-if="enableLotAvailabilityPage && menuItem.slot === $constants.injectedRouteNames.AVAILABILITY_ROUTE"
                :key="menuItem.slot" class="menu-link-t2-item menu-link-t2_availability"
                :to="{ name: $constants.SСHEME_PAGE, query: { results: 'show' } }">
                <div class="menu-link-t2__img-container">
                    <img :src="$getImagePath(`icons/${isDarkTheme ? 'picto-dispo-wt' : 'picto-dispo'}.svg`)" alt="#" />
                </div>
                <span class="menu-link-t2__label">Disponibilités</span>
            </router-link>
            <!-- <div v-if="enableContactForm && menuItem.slot === $constants.injectedRouteNames.CONTACT_ROUTE"
                :key="menuItem.slot" class="menu-link-t2-item menu-link-t2_contaxt-box">
                <a v-if="enableContactForm && menuItem.slot === $constants.injectedRouteNames.CONTACT_ROUTE"
                    :key="menuItem.slot" v-scroll-to="formAnchor" class="menu-link-t2-item menu-link-t2_contact-us"
                    :href="formAnchor" @click="onContactForm">
                    <div class="menu-link-t2__img-container">
                        <img :src="$getImagePath(`icons/message${!isDarkTheme ? '-wt' : ''}.svg`)" alt="#" />
                    </div>
                    <span class="menu-link-t2__label">Contactez-nous</span>
                </a>
            </div> -->
            <router-link v-if="showMenuItem(menuItem, menuItem.meta?.isActive(false))" :key="menuItem.path"
                class="menu-link-t2_gallery menu-link-t2-itemg" :to="menuItem.path">
                <div class="menu-link-t2-item" :class="{ 'mitem-wsub': menuItem.meta?.menuTemplate == 'LocationMenu' }">
                    <div class="menu-link-t2__img-container">
                        <img :src="$getImagePath(`icons/${menuItem.meta.icon}${isDarkTheme ? '-wt' : ''}.svg`)"
                            alt="#" />
                    </div>
                    <span class="menu-link-t2__label">{{ menuItem.meta.title }}</span>
                </div>
                <div v-if="isSubMenuVisible(menuItem, menuTemplatesNames.LOCATION, mediaItems)" class="menu-sub-hover">
                    <!-- <div class="menu-link-legend" v-if="Array.isArray(tourshotspots) && tourshotspots.length">Cyclorama</div> -->
                    <template v-for="(tour, tourIndex) in tourshotspots">
                        <template v-for="(room, roomIndex) in tour.rooms">
                            <template v-if="room.hotspots.length">
                                <div class="menu-link-t2__label"
                                    @click="updateTourRoomHotspots({ tourIndex: tourIndex, roomIndex: roomIndex, hotspotIndex: 0 })">
                                    <div>{{ room.title }}</div>
                                </div>
                            </template>
                        </template>
                    </template>
                    <!-- <div class="menu-link-legend" v-if="Array.isArray(mediaItems) && mediaItems.length">Media</div> -->
                    <div v-for="mediaItem in mediaItems" :key="mediaItem.id" class="menu-link-t2__label"
                        @click="setActiveEntity(mediaItem)">
                        <div>{{ mediaItem.title }}</div>
                    </div>
                    <!-- <div class="menu-link-legend" v-if="Array.isArray(maps) && maps.length">Map</div> -->
                    <div v-for="map in maps" :key="map.id" class="menu-link-t2__label" @click="setActiveEntity(map)">
                        <div>{{ map.title }}</div>
                    </div>
                </div>
                <div v-if="isSubMenuVisible(menuItem, menuTemplatesNames.TOURS, tours)" class="menu-sub-hover">
                    <div v-for="(tour, index) in tours" :key="tour.id" class="menu-link-t2__label"
                        @click="setSelectedTourIndex(index)">
                        <div>{{ tour.title }}</div>
                    </div>
                </div>
                <div v-if="isSubMenuVisible(menuItem, menuTemplatesNames.GALLERY, galleriesOptions)"
                    class="menu-sub-hover">
                    <template v-for="gallery in galleriesOptions">
                        <div v-if="$store.getters[`gallery/${gallery.activityFlag}`]" :key="gallery.id"
                            class="menu-link-t2__label" @click="setActiveComponent({
                                name: gallery.name,
                                componentName: gallery.componentName,
                            })">
                            <div>{{ gallery.title }}</div>
                        </div>

                    </template>
                </div>
            </router-link>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import menuItemMixin from '@front/mixins/menuItemMixin';
import locationMixin from '@front/mixins/locationMixin';
import galleryMenuMixin from '@front/mixins/galleryMenuMixin';
import { menu as galleriesMenu } from '@front/models/gallery-menu';
import { MENU_TEMPLATES_NAMES } from '@front/models/menu-templates';
import { template } from '@babel/core';

export default {
    name: 'HomeMenuRoutes',
    mixins: [menuOffTimeoutMixin, menuItemMixin, locationMixin, galleryMenuMixin],
    props: {
        menuRoutes: {
            type: Array,
            default: () => [],
        },
        isDarkTheme: {
            type: Boolean,
            default: () => false,
        },
        enableLotAvailabilityPage: {
            type: Boolean,
            default: () => false,
        },
        enableContactForm: {
            type: Boolean,
            default: () => false,
        },
        formAnchor: {
            type: String,
            default: () => '#',
        },
    },
    computed: {
        ...mapGetters({
            formAction: 'projectExtend/formAction',
            galleries: 'gallery/galleries',
            activeComponent: 'gallery/activeComponent',
            tours: 'tours/tours',
        }),
        galleriesOptions() {
            return {
                ...this.galleries,
                ...galleriesMenu,
            };
        },
        menuTemplatesNames() {
            return MENU_TEMPLATES_NAMES;
        },
    },
    mounted() {
        try {
            if (!this.isConfigLoaded) {
                this.getLocations();
                this.getTours();
                this.fetchGalleryConfig();
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
            setSelectedTourIndex: 'tours/setSelectedTourIndex',
            setSelectedRoomIndex: 'location/setSelectedRoomIndex',
            setIsShowMobileMenu: 'config/setIsShowMobileMenu',
            setActiveComponent: 'gallery/setActiveComponent'
        }),
        ...mapActions({
            getLocations: 'location/getLocations',
            getTours: 'tours/getTours',
            fetchGalleryConfig: 'gallery/fetchGalleryConfig',
        }),
        onContactForm(event) {
            if (this.formAction === this.$constants.extendFormActions.SCROLL) {
                this.$route.path !== '/' && this.$router.replace('/');
                this.hideMenuHandler();

                return;
            }

            event.preventDefault();
            this.setIsContactModalActive(true);
        },
        isSubMenuVisible(menuItem, name, items) {
            return menuItem.meta?.menuTemplate === name && Object.keys(items).length;
        },
    },
};
</script>
